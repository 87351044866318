<template>
  <v-container class="pa-0">
    <v-row no-gutters>
      <v-col cols="12">
        <v-sheet :elevation="0" color="white">
          <!-- <v-card color="rgb(255, 0, 0, 0)" :elevation="0"> -->
          <v-img
            class="white--text align-end card-image"
            :src="thumbnail"
            :max-height="maxHeight"
            :aspect-ratio="aspectRatio"
            :alt="title"
          >
          </v-img>
          <v-row no-gutters :class="{ 'px-2': isPopUp }">
            <v-col>
              <h3 class="truncate title-text mb-0 pt-1">
                {{ title }}
              </h3>
            </v-col>
          </v-row>
          <v-row
            no-gutters
            :class="['price-text align-center mb-0 mt-1', { 'px-2': isPopUp }]"
          >
            <v-col cols="12" class="d-flex align-center">
              <svg
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z"
                  fill="#FFE500"
                  stroke="#E9B500"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span class="ml-1">
                {{ rating.averageRating }} ({{ rating.totalNumRatings }})
              </span>
              <span class="pl-1">&#183;</span>
              <template v-if="priceScale > 0">
                <img
                  v-for="i in priceScale"
                  :key="i + 10"
                  class="ml-1"
                  src="@/assets/dollar.svg"
                  alt="dollar"
                />
              </template>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'DiningItemComponent',
  props: {
    title: String,
    thumbnail: String,
    rating: {
      averageRating: Number,
      totalNumRatings: Number,
    },
    priceScale: Number,
    maxHeight: {
      type: Number,
      default: 150,
    },
    aspectRatio: {
      type: Number || String,
      default: 1.5,
    },
    isPopUp: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.title-text {
  color: #000;
  @include font-size(16, 150, 600);
}

.price-text {
  color: #000;
  @include font-size(14, 150);
}

.truncate {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.outline-dollar {
  color: #000;
  opacity: 0.2;
}
.card-image {
  border-radius: 4px !important;
}

a:link,
a:visited {
  text-decoration: none;
}
</style>
