<template>
  <v-container class="pa-0">
    <v-row no-gutters>
      <v-col cols="12">
        <LMap
          ref="map"
          @update:center="centerUpdated"
          :zoom="10"
          :options="{
            dragging: true,
            doubleClickZoom: true,
            scrollWheelZoom: true,
          }"
          style="height: 100vh; width: 100%"
          :center="centerCoordinates"
        >
          <template v-if="mapSearchResults && mapSearchResults.length">
            <LMarker
              :icon="icon"
              v-for="(result, i) in mapSearchResults"
              :lat-lng="result.coordinates"
              :key="i"
            >
              <LPopup :closeOnClick="true">
                <div class="popup-content" @click="goToRestaurant(result.id)">
                  <DiningItemComponent
                    :title="result.title"
                    :thumbnail="result.thumbnail"
                    :rating="result.rating"
                    :priceScale="result.priceScale"
                    :isPopUp="true"
                  />
                </div>
              </LPopup>
            </LMarker>
          </template>
          <LTileLayer :url="letleafMapUrl" subdomains="abc" />
          <LControl class="custom-control" position="topright">
            <div @click="closeMap" class="trigger">
              <v-icon class="close-icon">
                {{
                  $vuetify.breakpoint.mdAndUp
                    ? 'mdi-close'
                    : 'mdi-format-list-bulleted'
                }}
              </v-icon>
              <span class="trigger-text pr-3">
                <template v-if="$vuetify.breakpoint.mdAndUp">
                  {{ $t('dining.close_map') }}
                </template>
                <template v-else>
                  {{ $t('dining.list_view') }}
                </template>
              </span>
            </div>
          </LControl>
          <LControl class="custom-control" position="topleft">
            <template>
              <div @click="redoSearch" class="px-3 py-2 map-trigger">
                <span class="trigger-text">
                  {{ $t('dining.redo_search') }}
                </span>
              </div>
            </template>
          </LControl>
        </LMap>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { LMap, LTileLayer, LMarker, LPopup, LControl } from 'vue2-leaflet';
import { icon } from 'leaflet';
import DiningItemComponent from './features/Search/DiningItemComponent';
import DiningMixins from './mixins/DiningMixins';

export default {
  name: 'MapComponent',
  mixins: [DiningMixins],
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LControl,
    DiningItemComponent,
  },

  props: {
    city: String,
    searchQuery: String,
    lng: [Number, String],
    lat: [Number, String],
    minPriceScale: [Number, String],
    maxPriceScale: [Number, String],
    minRating: [Number, String],
    radius: [Number, String],
  },

  data() {
    return {
      icon: icon({
        iconUrl: require('@/assets/diningMap.svg'),
      }),
      updatedCenterlat: null,
      updatedCenterlong: null,
      displayRedoTrigger: false,
    };
  },

  created() {
    if (!this.hasDiningSearch) {
      this.loadSearchContent();
    }
  },

  methods: {
    closeMap() {
      this.$router.push({
        name: 'dining-results',
        params: this.$route.params,
        query: {
          q: this.$route.query.q,
          city: this.$route.query.city,
          lng: this.$route.query.lng,
          lat: this.$route.query.lat,
        },
      });
    },

    goToRestaurant(id) {
      this.$router.push({
        name: 'dining-details',
        params: {
          id,
        },
      });
    },

    centerUpdated(center) {
      this.displayRedoTrigger = true;
      if (center) {
        this.updatedCenterlat = center.lat;
        this.updatedCenterlong = center.lng;
      }
    },

    goToResultsPage() {
      this.$router.push({
        name: 'dining-results',
        params: this.$route.params,
        query: {
          q: this.$route.query.q,
          city: this.$route.query.city,
          lng: this.$route.query.lng,
          lat: this.$route.query.lat,
        },
      });
    },

    redoSearch() {
      this.displayRedoTrigger = false;
      this.$router.push({
        name: 'dining-map',
        query: {
          q: this.$route.query.q,
          city: null,
          lng: this.newCenterCoordinates ? this.newCenterCoordinates.lng : null,
          lat: this.newCenterCoordinates ? this.newCenterCoordinates.lat : null,
        },
      });
      this.updateCenterCoordinates(this.newCenterCoordinates);
    },

    loadSearchContent(skip, take) {
      this.searchDining({
        city: this.city,
        lng: this.lng,
        lat: this.lat,
        tags: this.searchQueryList,
        minRating: this.selectedRating.min,
        minPriceScale: this.selectedPriceScale.min,
        maxPriceScale: this.selectedPriceScale.max,
        radius: this.selectedRadius.meters,
        skip: skip,
        take: take,
      });
    },
  },

  computed: {
    centerCoordinates() {
      if (this.hasDiningSearch) {
        const { lat, lng } = this.diningSearch;
        return [lat, lng];
      } else return [0, 0];
    },

    foundLocations() {
      return this.diningSearch &&
        this.diningSearch.items &&
        this.diningSearch.items.length
        ? this.diningSearch.items
        : [];
    },

    newCenterCoordinates() {
      return {
        lng: this.updatedCenterlong,
        lat: this.updatedCenterlat,
      };
    },

    letleafMapUrl() {
      return 'http://{s}.tile.osm.org/{z}/{x}/{y}.png';
    },

    mapSearchResults() {
      if (this.foundLocations && this.foundLocations.length) {
        return this.foundLocations.map((location) => {
          const { title, thumbnail, rating, priceScale, id } = location;
          const { latitude, longitude } = location.geoLocation;
          return {
            coordinates: [latitude, longitude],
            id,
            title,
            thumbnail,
            rating,
            priceScale,
          };
        });
      } else return [];
    },

    selectedRating() {
      return { min: this.minRating || '' };
    },

    selectedPriceScale() {
      return {
        min: this.minPriceScale || '',
        max: this.maxPriceScale || '',
      };
    },

    selectedRadius() {
      return {
        meters: this.radius * 1000 || '',
        kilometers: this.radius || '',
      };
    },

    searchQueryList() {
      if (this.searchQuery) return this.searchQuery.split(',');
      else return [];
    },
  },

  watch: {
    city(oldVal, newVal) {
      if (newVal !== oldVal) {
        this.loadSearchContent();
      }
    },

    searchQuery(oldVal, newVal) {
      if (newVal !== oldVal) {
        this.loadSearchContent();
      }
    },

    hasDiningSearch(val) {
      if (!val) {
        this.goToResultsPage();
      }
    },

    $route: {
      immediate: true,
      handler(val) {
        if (!!val && val.name == 'dining-map' && !this.hasDiningSearch) {
          this.goToResultsPage();
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .leaflet-popup {
  width: 212px;
}
::v-deep .leaflet-popup-content-wrapper {
  padding: 0px;
}
::v-deep .leaflet-popup-content {
  width: inherit !important;
  margin: 0px;
}
::v-deep .leaflet-popup-content p {
  margin: 0px;
}
::v-deep .leaflet-bottom {
  bottom: 1;
}
.custom-control {
  background: #fff;
  cursor: pointer;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
}
.trigger {
  cursor: pointer;
}
.close-icon {
  color: var(--v-grey7-base);
  font-size: 14px;
  padding: 8px 0 8px 12px;
}
.trigger-text {
  color: #05090f;
  font-size: 14px;
}
.popup-content {
  cursor: pointer;
}
.map-trigger {
  z-index: 100;
  background: #fff;
  border: 1px solid #cfd5e0;
  box-sizing: border-box;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  @include font-size(14, 150, 600);
  color: #000;
}
.trigger-wrapper {
  cursor: pointer;
}
</style>
